import React, { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
	children: ReactNode
	className?: string
	font?: 'normal' | 'body'
}

const baseStyles = 'font-inter font-extrabold tracking-tight text-gray-900 !leading-tight'
const altStyles = 'font-body font-semibold tracking-normal text-gray-900 lg:leading-tight'

export function H1({ children, className, font = 'normal', ...props }: HeadingProps) {
	return (
		<h1 {...props} className={twMerge('text-4xl md:text-5xl xl:text-6xl', font === 'normal' ? baseStyles : altStyles, className)}>
			{children}
		</h1>
	)
}

export function H2({ children, className, font = 'normal', ...props }: HeadingProps) {
	return (
		<h2 {...props} className={twMerge('text-3xl md:text-4xl xl:text-5xl', font === 'normal' ? baseStyles : altStyles, className)}>
			{children}
		</h2>
	)
}

export function H3({ children, className, font = 'normal', ...props }: HeadingProps) {
	return (
		<h3 {...props} className={twMerge('text-2xl md:text-3xl xl:text-4xl', font === 'normal' ? baseStyles : altStyles, className)}>
			{children}
		</h3>
	)
}

export function H4({ children, className, font = 'normal', ...props }: HeadingProps) {
	return (
		<h4 {...props} className={twMerge('text-xl md:text-2xl xl:text-3xl', font === 'normal' ? baseStyles : altStyles, className)}>
			{children}
		</h4>
	)
}

export function H5({ children, className, font = 'normal', ...props }: HeadingProps) {
	return (
		<h5 {...props} className={twMerge('text-lg md:text-xl xl:text-2xl', font === 'normal' ? baseStyles : altStyles, className)}>
			{children}
		</h5>
	)
}

export function H6({ children, className, font = 'normal', ...props }: HeadingProps) {
	return (
		<h5 {...props} className={twMerge('text-base md:text-lg xl:text-xl', font === 'normal' ? baseStyles : altStyles, className)}>
			{children}
		</h5>
	)
}
