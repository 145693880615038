import './ThemeCard.scss'

import { Button } from '@arckit/ui'
import { Col } from 'components/grid'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
// @ts-ignore
import Zoom from 'react-reveal/Zoom'
import { IThemeCard } from 'components/themes/Themes'
import { H4 } from 'components/typography/Headings'

interface Props {
	theme: IThemeCard
	passDataToModal: (name: string, grades: string[]) => void
}

class ThemeCard extends React.Component<Props> {
	render() {
		const { theme, passDataToModal } = this.props

		return (
			<React.Fragment>
				<Col width="w-full md:w-1/2 lg:w-1/3" className={`mb-6 filter ${theme.slug}`}>
					<Zoom>
						<div className="flex h-full flex-col overflow-hidden rounded bg-white shadow-lg">
							{theme.coverImage ? (
								<Link to={`/themes/${theme.slug}`}>
									<GatsbyImage
										className="transform rounded-t transition duration-200 ease-in-out hover:scale-105"
										image={theme.coverImage.gatsbyImageData}
										alt={`${theme.name} cover`}
									/>
								</Link>
							) : (
								<Link to={`/themes/${theme.slug}`}>
									<img src="https://via.placeholder.com/442x275.png?text=Theme+Image+Coming+Soon" alt="theme cover" className="rounded-t" />
								</Link>
							)}
							<div className="flex flex-auto flex-col p-5">
								<Link to={`/themes/${theme.slug}`} className="hover:no-underline hover:opacity-75">
									<H4 font="body" className="mb-2 lg:leading-none">
										{theme.name}
									</H4>
								</Link>
								<p className="text-lg font-medium text-ab-100">Grade {theme.gradeLevel}</p>
								<span className="mb-3 text-lg">
									Research Lab:{' '}
									<span>
										{theme.hasResearchLab ? (
											<img src="/images/utils/checkmark.svg" alt="check mark" className="ml-2 inline" style={{ height: 20, marginBottom: 5 }} />
										) : (
											'N/A'
										)}
									</span>
								</span>
								<span className="mb-3 flex items-end text-lg">
									Digital Resources:{' '}
									<span className="ml-2 flex">
										{theme.digitalResources ? (
											theme.digitalResources === 'Curriculum Resource Center' ? (
												'N/A'
											) : (
												<button onClick={() => passDataToModal(theme.name, theme.schoolPaceConnectGrades)}>
													<img src="/images/logos/schoolpace-connect.svg" alt="schoolpace connect logo" style={{ height: 20, marginBottom: 5 }} />
												</button>
											)
										) : (
											'N/A'
										)}
									</span>
								</span>
								{theme.basketLevels ? (
									<div className="mb-3 flex">
										{theme.basketLevels.map((basket) => {
											return <span className={`basket level-${basket} block h-6 w-6 bg-center bg-no-repeat`} key={`${theme.id}-${basket}`} />
										})}
									</div>
								) : (
									<p>No baskets available</p>
								)}

								<div className="mt-auto">
									<Link to={`/themes/${theme.slug}`}>
										<Button fullWidth>Explore This Theme</Button>
									</Link>
								</div>
							</div>
						</div>
					</Zoom>
				</Col>
			</React.Fragment>
		)
	}
}

export default ThemeCard
