import { Row } from 'components/grid'
import { Link } from 'gatsby'
import React, { useState } from 'react'
import { Collapse } from 'react-collapse'

interface Props {
	items: { link?: string; name: string; route?: string }[]
}

const SubNav = ({ items }: Props) => {
	const [menuOpen, setMenuOpen] = useState(false)

	return (
		<div className="sticky top-0 z-[1]">
			<Row className="bg-white shadow">
				{/* Hamburger Menu Icon */}
				<div className="flex w-full justify-end p-4 lg:hidden">
					<button onClick={() => setMenuOpen(!menuOpen)}>
						<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
							<path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
						</svg>
					</button>
				</div>
				{/* Mobile Menu */}
				<Collapse
					isOpened={menuOpen}
					theme={{
						collapse: 'ReactCollapse--collapse transition-all duration-200 ease-in-out w-full',
						content: 'ReactCollapse--content'
					}}
				>
					<div className="flex w-full flex-col flex-wrap justify-center gap-3 bg-white py-3 sm:flex-row">
						{items.map((item) => {
							if (item.route)
								return (
									<Link
										key={item.route}
										className="border border-transparent p-3 text-sm hover:border-gray-300 hover:bg-slate-50 hover:no-underline"
										to={item.route}
									>
										{item.name}
									</Link>
								)
							return (
								<a
									onClick={() => setMenuOpen(false)}
									key={item.name}
									href={`#${item.link}`}
									className="border border-transparent p-3 text-sm hover:border-gray-300 hover:bg-slate-50 hover:no-underline"
								>
									{item.name}
								</a>
							)
						})}
					</div>
				</Collapse>
				{/* Desktop Menu */}
				<div className="hidden w-full flex-col flex-wrap justify-center gap-3 bg-white py-3 sm:flex-row lg:flex">
					{items.map((item) => {
						if (item.route)
							return (
								<Link key={item.name} className="border border-transparent p-3 text-sm hover:border-gray-300 hover:bg-slate-50 hover:no-underline" to={item.route}>
									{item.name}
								</Link>
							)
						return (
							<a
								onClick={() => setMenuOpen(false)}
								key={item.name}
								href={`#${item.link}`}
								className="border border-transparent p-3 text-sm hover:border-gray-300 hover:bg-slate-50 hover:no-underline"
							>
								{item.name}
							</a>
						)
					})}
				</div>
			</Row>
		</div>
	)
}

export default SubNav
