import React, { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface ParagraphProps extends React.HTMLAttributes<HTMLParagraphElement> {
	children: ReactNode
	className?: string
	shade?: 'lighter' | 'light' | 'normal' | 'dark' | 'darker' | 'darkest'
	size?: 'xs' | 'sm' | 'md' | 'lg'
}

function determineFontSize(size: ParagraphProps['size']) {
	if (size === 'xs') return 'text-xs'
	if (size === 'sm') return 'md:text-base'
	if (size === 'md') return 'text-base md:text-lg'
	if (size === 'lg') return 'text-lg md:text-xl'
	else return 'text-base md:text-lg'
}

function determineFontColor(shade: ParagraphProps['shade']) {
	if (shade === 'lighter') return 'text-gray-300'
	if (shade === 'light') return 'text-gray-400'
	if (shade === 'normal') return 'text-gray-500'
	if (shade === 'dark') return 'text-gray-600'
	if (shade === 'darker') return 'text-gray-700'
	if (shade === 'darkest') return 'text-gray-800'
	else return 'text-gray-500'
}

export function P({ children, className, shade = 'normal', size = 'lg', ...props }: ParagraphProps) {
	return (
		<p {...props} className={twMerge('font-normal', determineFontSize(size), determineFontColor(shade), className)}>
			{children}
		</p>
	)
}
