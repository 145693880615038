import { Select } from 'components/global/FormInputs'
import { Col, Row } from 'components/grid'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import scrollTo from 'gatsby-plugin-smoothscroll'
import React, { useEffect, useState } from 'react'
// @ts-ignore
import Fade from 'react-reveal/Fade'
// @ts-ignore
import Slide from 'react-reveal/Slide'

interface Props {
	grade: string
	subject: string
	handleGlobalSubject: (subject: string) => void
	handleGlobalGrade: (grade: string) => void
}

const ThemeFilters = ({ grade, subject, handleGlobalSubject, handleGlobalGrade }: Props) => {
	const [category, setCategory] = useState('top')

	const grades = ['K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
	const subjects = ['Life Science', 'Earth & Space Science', 'Physical Science', 'World History', 'American History', 'World Geography & Cultures']
	const categories = [
		{ value: 'science-and-social-studies', name: 'Research Lab Themes' },
		{ value: 'spanish-science-and-social-studies', name: 'Spanish Research Labs Themes' },
		{ value: 'literature-genre-studies', name: 'Genre Themes' },
		{ value: 'spanish-literature-genre-studies', name: 'Spanish Genre Themes' },
		{ value: 'genre-text-sets', name: 'Genre Text Sets' },
		{ value: 'additional-text-sets', name: 'Additional Text Sets' }
	]

	useEffect(() => {
		scrollTo(`#${category}`)
	}, [category])

	return (
		<Row className="items-center">
			<Col width="w-full md:w-5/12" className="">
				<div className="-mr-0 md:-mr-40">
					<Slide left>
						<StaticImage src="../../images/themes/choosing-books-in-library.jpg" placeholder="blurred" alt="something" className="" />
					</Slide>
				</div>
			</Col>
			<Col width="w-full md:w-7/12" className="bg-white py-0 pl-4 md:bg-slate-50 md:py-56 md:pl-48">
				<Slide top>
					<span className="mb-2 block text-xl font-bold md:text-3xl">Explore Content-Rich Themes & Text Sets by Grade or Subject</span>
				</Slide>
				<div className="mt-4 flex flex-wrap items-center xl:flex-nowrap">
					<Fade bottom>
						<div className="mr-3 mb-3 flex items-center">
							<svg xmlns="http://www.w3.org/2000/svg" className="mr-2 hidden h-6 w-6 text-slate-500 xl:block" viewBox="0 0 20 20" fill="currentColor">
								<path
									fillRule="evenodd"
									d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
									clipRule="evenodd"
								/>
							</svg>
							<Select
								id="global-theme-subject-filter"
								className="truncate "
								name="subject"
								value={subject}
								onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleGlobalSubject(e.target.value)}
							>
								<option value="all">All Subjects</option>
								{subjects.map((subject) => {
									return (
										<option value={subject} key={subject}>
											{subject}
										</option>
									)
								})}
							</Select>
						</div>
						<div className="mr-3 mb-3">
							<Select
								id="global-theme-grade-filter"
								className=""
								style={{ minWidth: 100 }}
								name="grade"
								value={grade}
								onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleGlobalGrade(e.target.value)}
							>
								<option value="all">K-12</option>
								{grades.map((grade) => {
									return (
										<option value={grade} key={grade}>
											{grade}
										</option>
									)
								})}
							</Select>
						</div>
						<div className="mb-3">
							<Select
								id="global-theme-catagory-filter"
								name="category"
								value={category}
								onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setCategory(e.target.value)}
							>
								<option value="all">Select Category</option>
								{categories.map((category) => {
									return (
										<option value={category.value} key={category.value}>
											{category.name}
										</option>
									)
								})}
							</Select>
						</div>
					</Fade>
				</div>
				<div className="my-6 mx-auto w-64 border-b">
					<span className="mx-auto -mb-3 block w-16 bg-slate-50 text-center font-semibold text-slate-500">OR</span>
				</div>
				<div className="mt-10">
					<Link to="/themes/all-themes" className="block w-full text-center">
						View All Themes
					</Link>
				</div>
			</Col>
		</Row>
	)
}

export default ThemeFilters
