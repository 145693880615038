import React from 'react'
import { LabelProps, TextInputProps, TextAreaProps, SelectProps, RadioProps, ValidationErrorProps } from './form-input.props'

export const Label = ({ name, htmlFor, required, bold }: LabelProps) => {
	return (
		<label className={`text-ms mb-1 block font-semibold uppercase tracking-wide text-slate-800 ${bold && 'text-lg font-medium'}`} htmlFor={htmlFor}>
			{name}
			{required && <sup className="ml-1 text-red-500">*</sup>}
		</label>
	)
}

export const TextInput = ({ id, name, value, required, validation, search, type, className, ...props }: TextInputProps) => {
	if (search) {
		return (
			<div className="relative w-full">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="absolute top-[14px] left-[10px] h-5 w-5 text-slate-800 opacity-25"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
					strokeWidth={2}
				>
					<path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
				</svg>
				<input
					id={id}
					type={type}
					name={name}
					value={value}
					required={required}
					ref={validation}
					className={`w-full rounded-sm border-gray-200 py-3 pr-4 pl-10 hover:shadow-inner focus:border-gray-500 focus:shadow-inner focus:outline-none ${
						className ? className : ''
					}`}
					{...props}
				/>
			</div>
		)
	} else {
		return (
			<input
				id={id}
				type={type}
				name={name}
				value={value}
				required={required}
				ref={validation}
				className={`w-full rounded-sm border-gray-200 px-4 py-3 hover:shadow-inner focus:border-gray-500 focus:shadow-inner focus:outline-none ${
					className ? className : ''
				}`}
				{...props}
			/>
		)
	}
}

export const TextArea = ({ id, name, value, required, validation, ...props }: TextAreaProps) => {
	return (
		<textarea
			id={id}
			name={name}
			value={value}
			required={required}
			ref={validation}
			className="rounded-ms w-full border-gray-200 px-4 py-3 hover:shadow-inner focus:border-gray-500 focus:shadow-inner focus:outline-none"
			{...props}
		/>
	)
}

export const Select = ({ id, name, value, className, required, validation, children, ...props }: SelectProps) => {
	return (
		<select
			id={id}
			name={name}
			value={value}
			required={required}
			ref={validation}
			className={`rounded-ms w-full appearance-none border-gray-200 bg-white py-3 pl-4 pr-10 focus:border-gray-500 focus:shadow-inner focus:outline-none ${
				className ? className : ''
			}`}
			{...props}
		>
			{children}
		</select>
	)
}

export const Radio = ({ id, name, value, required, validation, className, ...props }: RadioProps) => {
	return (
		<div>
			<label className="inline-flex items-center">
				<input
					type="radio"
					required={required}
					ref={validation}
					className={`form-radio border-gray-200 text-ab-100 ring-1 ring-black ring-opacity-5 focus:shadow-none focus:outline-none ${className ? className : ''}`}
					name={name}
					value={value}
					id={id}
					{...props}
				/>
				<span className="ml-2">{value}</span>
			</label>
		</div>
	)
}

export const ValidationError = ({ className, children }: ValidationErrorProps) => {
	return <span className={`mt-1 block text-sm font-medium text-red-500 ${className ? className : ''}`}>{children}</span>
}
