import { Col, Row, Section } from 'components/grid'
import Layout from 'components/Layout'
import SubNav from 'components/SubNav'
import ThemeFilters from 'components/themes/ThemeFilters'
import Themes from 'components/themes/Themes'
import { H1 } from 'components/typography/Headings'
import { P } from 'components/typography/Paragraph'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react'
// @ts-ignore
import Slide from 'react-reveal/Slide'
import ReactTooltip from 'react-tooltip'

export default function ThemesPage() {
	const [isClient, setIsClient] = useState(false)
	// This is the state for the global grade and subject
	const [globalSubject, setGlobalSubject] = useState('all')
	const [globalGrade, setGlobalGrade] = useState('all')

	// This function handles setting the the globalSubject and gets passed to the ThemeFilter component
	const handleGlobalSubject = (subject: string) => {
		setGlobalSubject(subject)
	}

	// This function handles setting the the globalGrade and gets passed to the ThemeFilter component
	const handleGlobalGrade = (grade: string) => {
		setGlobalGrade(grade)
	}

	const subNavItems = [
		{ name: 'Science & Social Studies Themes', link: 'science-and-social-studies' },
		{ name: 'Spanish Themes', link: 'spanish-science-and-social-studies' },
		{ name: 'Genre Themes', link: 'literature-genre-studies' },
		{ name: 'Spanish Genre Themes', link: 'spanish-literature-genre-studies' },
		{ name: 'Genre Text Sets', link: 'genre-text-sets' },
		{ name: 'Additional Text Sets', link: 'additional-text-sets' },
		{ name: 'All Themes', route: '/themes/all-themes' }
	]

	useEffect(() => {
		setIsClient(true)
	}, [])

	return (
		<Layout subNav pageName="Themes">
			<SubNav items={subNavItems} />
			<Section flatGray>
				<Row middle className="pb-12 pt-12 md:pb-16 md:pt-0">
					<Col width="w-full md:w-1/2">
						<Slide left cascade>
							<div>
								<H1 className="mb-3">Thematic Units</H1>
								<P>
									Thematic units focused on social studies, science, or a literary genre immerse students in high-quality texts and meaningful content that build
									the prior knowledge needed to be more effective readers and learners.
								</P>
								<StaticImage src="../images/themes/leveling-bar.png" placeholder="blurred" alt="leveling bar" className="mt-8" />
							</div>
						</Slide>
					</Col>
					<Col width="w-full md:w-1/2" className="hidden md:block">
						<Slide right>
							<StaticImage src="../images/themes/girl-picking-book.png" placeholder="blurred" alt="kids reading" className="hidden sm:block" />
						</Slide>
					</Col>
				</Row>
			</Section>
			<Section margin="mt-4 sm:mt-20">
				<ThemeFilters grade={globalGrade} subject={globalSubject} handleGlobalGrade={handleGlobalGrade} handleGlobalSubject={handleGlobalSubject} />
			</Section>
			<Section margin="mt-10 sm:mt-24 scroll-mt-20" id="science-and-social-studies">
				<Themes themeType="Research Lab" sectionName="Science & Social Studies Themes With Research Labs" globalGrade={globalGrade} globalSubject={globalSubject} />
			</Section>
			<Section margin="scroll-mt-20" id="spanish-science-and-social-studies">
				<Themes themeType="Spanish" sectionName="Spanish Science & Social Studies Themes With Research Labs" globalGrade={globalGrade} globalSubject={globalSubject} />
			</Section>
			<Section margin="scroll-mt-20" id="literature-genre-studies">
				<Themes themeType="Genre" sectionName="Literature Genre Studies" globalGrade={globalGrade} globalSubject={globalSubject} />
			</Section>
			<Section margin="scroll-mt-20" id="spanish-literature-genre-studies">
				<Themes themeType="Spanish Genre" sectionName="Spanish Literature Genre Studies" globalGrade={globalGrade} globalSubject={globalSubject} />
			</Section>
			<Section margin="scroll-mt-20" id="genre-text-sets">
				<Themes themeType="ELA" sectionName="Genre Text Sets" globalGrade={globalGrade} globalSubject={globalSubject} />
			</Section>
			<Section margin="scroll-mt-20" id="additional-text-sets">
				<Themes themeType="Text Set" sectionName="Additional Text Sets" globalGrade={globalGrade} globalSubject={globalSubject} />
			</Section>
			{isClient && (
				<ReactTooltip effect="solid" backgroundColor="#fff" textColor="#304157" borderColor="#E5E7EB" border className="max-w-xs text-center !text-base !opacity-100" />
			)}
		</Layout>
	)
}
